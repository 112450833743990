import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "./css/cookiebot.css";
import './js/cookiebot';

// Kunsthaus
import Home from "./views/Home";
import CalendarAbramovic from "./views/Step10_Abramovic_Calendar";
import ToursAbramovic from "./views/Step15_Tours_Abramovic";
import TicketsAbramovic from "./views/Step15_Tickets_Abramovic";
import TicketsWongVanGogh from "./views/Step10_Tickets_WongVanGogh";
import CalendarAllInclusive from "./views/Step10_AllInclusive_Calendar";
import TicketsAllInclusive from "./views/Step15_Tickets_AllInclusive";
import TicketsCollection from "./views/Step10_Tickets_Collection";
import PreviewAbramovic from "./views/Step15_Preview_Abramovic";
import Shipping from "./views/Step20_Shipping";
import Checkout from "./views/Step30_Checkout";
import Confirmation from "./views/Step40_Confirmation";

import CurrentTimeSlotsOverview from "./views/CurrentTimeSlotsOverview"; // current day timeslots

import ScrollToTop from './views/components/ScrollToTop';

// Fallback page
import PageNotFound from "./views/components/PageNotFound";

function App() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="*" element={<PageNotFound />} />
                <Route path="/" element={<Navigate to="/de/home" replace />} />
                <Route path="/:language/home" element={<Home />} />
                <Route path="/:language/marina-abramovic" element={<CalendarAbramovic />} />
                <Route path="/:language/marina-abramovic-tours" element={<ToursAbramovic />} />
                <Route path="/:language/marina-abramovic-tickets/:eventId" element={<TicketsAbramovic />} />
                <Route path="/:language/matthew-wong-van-gogh" element={<TicketsWongVanGogh />} />
                <Route path="/:language/marina-abramovic-preview" element={<PreviewAbramovic />} />
                <Route path="/:language/all-inclusive" element={<CalendarAllInclusive />} />
                <Route path="/:language/all-inclusive-tickets/:eventId" element={<TicketsAllInclusive />} />
                <Route path="/:language/sammlung" element={<TicketsCollection />} />
                <Route path="/:language/shipping" element={<Shipping />} />
                <Route path="/:language/checkout" element={<Checkout />} />
                <Route path="/:language/confirmation" element={<Confirmation />} />
                <Route path="/:language/confirmation/:orderId" element={<Confirmation />} />

                <Route path="/:language/timeslots-overview" element={<CurrentTimeSlotsOverview />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;