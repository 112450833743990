import $ from 'jquery';

// Cookiebot toggler button functionality
$(document).on('click', '.cookiebot-toggler-button', function (event) {
    event.preventDefault();
    let $cookiebanner = $(this).closest('#CookiebotBanner');
    let $cookiebotSub = $cookiebanner.find('.cookiebot-sub');
    $cookiebanner.toggleClass('is-expanded');
    $cookiebotSub.slideToggle();
});

// Event for hiding the Cookie Declaration "Change Consent" button
$(document).on('click', '#CookieDeclarationChangeConsentWithdraw', function (event) {
    event.preventDefault();
    $(this).hide();
});

// Event for handling Cookiebot consent acceptance or dialog display
$(window).on('CookiebotOnAccept CookiebotOnDialogDisplay', function (event) {
    var withDrawLabel = document.getElementById("CookieDeclarationUserStatusLabelWithdraw");
    if (withDrawLabel && withDrawLabel.style.display === 'inline-block') {
        withDrawLabel.style.display = 'inline';
    }
});

// Select the checkboxes
$(document).on('change', "#CookiebotBanner .selection-item input[type='checkbox']", function (event) {
    $(this).parent().toggleClass("is-active", $(this).prop("checked"));
    $(this).prop("checked", $(this).parent().hasClass("is-active"));
});